<!-- src/components/MainGlobal.vue -->
<template>
    <main>
    <section class="home lamina">
        <div class="container">
            <div class="columns twelve">
                <img src="/images/logo-vicapital.svg" alt="Vicapital" class="logo-home"/>
                <h2 class="anima-1 blur">MULTI FAMILY OFFICE</h2>
            </div>
        </div>
        <a href="#section-1" class="arrow anima-2 blur"></a>
        <div class="video">
            <video loop="" muted="" autoplay="">                
                <source src="/videos/video-home-vicapital.mp4" type="video/mp4">               
                Your browser does not support the video tag.
            </video>
        </div>
    </section>

    <div class="full" style="background-image: url('/images/fondo_nuevo.jpg');">
        <section id="section-1" class="laminita">
            <div class="container">
                <div class="columns eight offset-by-two">
                    <div class="columns twelve">
                        <h2>About<br>
                        Us</h2>
                    </div>
                    <div class="columns ten offset-by-one centrado">
                        <p>Vicapital is an independent investment firm offering advisory services for high-net-worth individuals, families, and foundations through personalized and customized service. We have a team of professionals with outstanding experience in investments, dedicated exclusively to the care of our clients’ wealth.</p>
                    </div>
                </div>
                <a href="#section-2" class="arrow"></a>
            </div>
        </section>

        <section id="section-2" class="nuestros-pilares2">
            <div class="container">
                <div class="columns eight offset-by-two">
                    <div class="columns twelve">
                        <h2>Our<br>Pillars</h2>
                    </div>
                    <div class="columns five offset-by-four">
                        <div class="item-cifras">
                            <div class="numero columns one same">01</div>
                            <div class="caja-pilar columns ten same">
                                <h3>Independence</h3>
                                <div class="texto-85">
                                  We are an independent firm, with our sole objective being to <strong>identify the best market opportunities and advise</strong> our clients according to their goals.</div>
                            </div>
                        </div>
                        <div class="item-cifras">
                            <div class="numero columns one same">02</div>
                            <div class="caja-pilar columns ten same">
                                <h3>Experience</h3>
                                <div class="texto-85">We have a highly experienced team, where <strong>the partners are fully involved</strong> in the relationship with each client.</div>
                            </div>
                        </div>
                        <div class="item-cifras">
                          <div class="numero columns one same">03</div>
                          <div class="caja-pilar columns ten same">
                              <h3>Focus</h3>
                              <div class="texto-85">Our clients’ wealth advisory is our <strong>only business</strong>, allowing us to have <strong>exclusive dedication and be specialists.</strong></div>
                          </div>
                      </div>
                      <div class="item-cifras">
                        <div class="numero columns one same">04</div>
                        <div class="caja-pilar columns ten same">
                            <h3>Transparency</h3>
                            <div class="texto-85">We believe in <strong>long-term relationships</strong>, so <strong>confidentiality and transparency</strong> are essential pillars of our company to build trust.</div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="section-3" class="mision-vision">
            <div class="container">
                <div class="columns ten">
                    <div class="columns six offset-by-two">
                        <div class="item-cifras">
                            <div class="numero2 columns one same">MISSION</div>
                            <div class="caja-pilar columns ten same">
                              <h3 style="visibility: hidden;">TEST</h3>
                                <div class="texto-101"><strong>To provide specialized wealth advisory focused on the individual needs of our clients, guiding them towards investment decisions that allow them to achieve their long-term goals.</strong></div>
                            </div>
                        </div>
                        <div class="item-cifras">
                            <div class="numero2 columns one same">VISION</div>
                            <div class="caja-pilar columns ten same">
                                <h3 style="visibility: hidden;">TEST</h3>
                                <div class="texto-101"><strong> To be leaders in wealth advisory, recognized for our integrity and commitment to our clients' success.</strong></div>
                            </div>
                        </div>
                        <div class="item-cifras">
                          <div class="numero2 columns one same">OBJECTIVES</div>
                          <div class="caja-pilar columns ten same">
                              <h3 style="visibility: hidden;">TEST</h3>
                              <div class="texto-100">
                              <ul class="custom-list">  
                                <li><strong>To build trust-based, long-term relationships with our clients, founded on mutual respect and transparency.</strong></li>
                                <li><strong>To guide each client in structuring investment portfolios designed according to their risk profile and financial objectives.</strong></li>
                                <li><strong>To enhance teamwork, maintaining a high professional standard and a firm commitment to achieving each client’s objectives.</strong></li>
                                <li><strong>To continuously monitor the market to stay aware of global economic trends and factors.</strong></li>
                                <li><strong>To maintain clear and constant communication, ensuring that clients are well-informed about their investments and market dynamics in an understandable and transparent manner.</strong></li>
                              </ul>

                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>


    </div>
</main>
  </template>
  
  <script>
  import jQuery from 'jquery'
  import { useHead } from '@vueuse/head';
  export default {
    name: 'MainEng',
    setup() {
    useHead({
      title: 'Home | VICAPITAL',
      meta: [
        { name: 'description', content: '' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'Home | VICAPITAL' },
        { property: 'og:description', content:  '' },
        { property: 'og:url', content: 'https://vicapital.cl/en' },
        { property: 'og:locale', content: 'en_US' },
      ],
      link:
      [{ rel: 'canonical', href: 'https://vicapital.cl/en' }]
    });
  },
  data() {
    return {
      imagesToPreload: [
        '/images/fondo-que-hacemos-exp-scaled.jpg',
        '/images/fondo-filosofia-exp-scaled.jpg',
        '/images/fondo-equipo-1.jpg',
        '/images/fondo-contacto-exp-scaled.jpg',
        '/images/fondo-filosofia-exp-scaled.jpg',
        // Agrega aquí todas las imágenes que necesites pre-cargar
      ]
    };
  },
    mounted() {
        this.initPage();
        this.setupWindowLoadEvent();
        this.preloadImages();
    },

  methods: {
    preloadImages() {

this.imagesToPreload.forEach((src) => {
  const img = new Image();
  img.src = src;
  img.onload = () => {
  };
});
}
,
    initPage() {
      //console.log("iniciando init en main");
      
      this.$nextTick(() => {
        var $j = jQuery.noConflict();
        //console.log("No Conflict activado");

        const id = (v) => document.getElementById(v);

        var ovrl = id("overlay");
        if (ovrl) {
          $j('#overlay .logo').css('transition', 'all 1.5s ease');
          $j('#loader-logo-hover').css('opacity', '1');
          $j('.loader-logo').css('transition', 'all 0.2s ease');
          $j('.loader-logo').css('opacity', '0');
          setTimeout(function() {
            ovrl.style.opacity = 0;
          }, 1200);
          setTimeout(function() {
            ovrl.style.display = "none";
            $j('body').css('overflow', 'auto');
            $j('header').addClass('show-menu');
          }, 2000);
          //console.log("Elemento overlay encontrado");
        } else {
          //console.error("Elemento overlay no encontrado");
        }

        // Function to equalize heights
        

        // Window scroll and load functions
        const setupScrollEvents = () => {
          //console.log("Función principal de jQuery ejecutada");
          var height = $j(window).height(),
              header = $j("header");

          $j(window).scroll(() => {
            var scroll = $j(window).scrollTop();
            if (scroll >= height) {
              header.removeClass('sin-scroll').addClass("scroll");
            } else {
              header.removeClass("scroll").addClass('sin-scroll');
            }
          });

          $j(window).scroll(() => {
            var scroll = $j(window).scrollTop(),
                triggerBlur = $j(window).height() / 3;
            if (scroll >= triggerBlur) {
              $j('.full.blur').addClass("activo");
            } else {
              $j('.full.blur').removeClass("activo");
            }
          });
        };
        setupScrollEvents();

        
    

        $j(window).resize(() => {
          //console.log("Evento resize");

          this.equalheight('.same');
          var height = $j(window).height(),
              width = $j(window).width();
          if (width > 950){
            $j('.lamina').css('height',height);
          }
        });

        const setupMenuEvents = () => { 
          //console.log("Función anónima de jQuery ejecutada");
          $j('.nav .menu-item').has('ul').prepend('<span class="nav-click"><i class="nav-arrow"></i></span>');

          $j('.open-menu').click(function(){
            $j('header').toggleClass('open');
            $j('body').toggleClass('open-body');
            $j(this).toggleClass('open');
            return false;
          });
          /*
          $j('nav.principal .menu-item a').click(function(){
            console.log(location.pathname.replace(/^\//,''))
            console.log(this.pathname.replace(/^\//,''))
            console.log(location.hostname)
            console.log(this.hostname)
            
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
              var target = $j(this.hash);
              //console.log(target)

              target = target.length ? target : $j('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $j('html,body').animate({
                  scrollTop: target.offset().top
                }, 2000);
                $j('header').toggleClass('open');
                $j('body').toggleClass('open-body');
                $j('.open-menu').toggleClass('open');
                console.log('click');
                return false;
              }
            }
          });
          */

          $j('nav.info a').click(function(){
            var target = $j(this.hash);
            $j('.pop-left').removeClass('open');
            $j(target).toggleClass('open');
            return false;
          });

          $j('a.close-pop').click(function(){
            var target = $j(this.hash);
            $j(target).toggleClass('open');
            return false;
          });

          $j('.nav-responsive .nav-click').on('click', function(){
            if($j(this).parent().hasClass('activo')){
              $j('nav.nav .menu-item').removeClass('activo');
            } else {
              $j('nav.nav .menu-item').removeClass('activo');
              $j(this).parent().toggleClass('activo');
            }
            //console.log('submenu');
          });
          /*
          $j('.scroll').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
              var target = $j(this.hash);
              target = target.length ? target : $j('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $j('html,body').animate({
                  scrollTop: target.offset().top
                }, 2000);
                console.log('.scroll');
                return false;
              }
            }
          });
          */
        };

        setupMenuEvents();
      });
    },

    setupWindowLoadEvent() {

        this.equalheight = function(container) {
          //console.log("Iniciando equalheight");
          var currentTallest = 0,
              currentRowStart = 0,
              rowDivs = [],
              $el,
              topPosition = 0;

          $j(container).each(function() {
            $el = $j(this);
            $j($el).height('auto');
            topPosition = $el.position().top;

            if (currentRowStart != topPosition) {
              for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
              }
              rowDivs.length = 0; // empty the array
              currentRowStart = topPosition;
              currentTallest = $el.height();
              rowDivs.push($el);
            } else {
              rowDivs.push($el);
              currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
            }
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
          });
          //console.log("equalheight completado");
        }
            const $j = jQuery.noConflict();
          //console.log("Entrando a evento window load");
          
          this.equalheight('.same');
          var height = $j(window).height();

          const id = (v) => document.getElementById(v);
          var ovrl = id("overlay");
          $j('#overlay .logo').css('transition','all 1.5s ease');
          $j('#loader-logo-hover').css('opacity','1');
          $j('.loader-logo').css('transition','all 0.2s ease');
          $j('.loader-logo').css('opacity','0');
          setTimeout(function(){ 
            ovrl.style.opacity = 0;
          }, 1200);
          setTimeout(function(){ 
            ovrl.style.display = "none";
            $j('body').css('overflow','auto');
          }, 2000);
          
          setTimeout(function(){ 
            $j('section.home .anima-1').addClass('activo');
          }, 2000);
          setTimeout(function(){ 
            $j('section.home .anima-2').addClass('activo');
          }, 2500);
          setTimeout(function(){ 
            $j('section.home .anima-3').addClass('activo');
          }, 4000);
          setTimeout(function(){ 
            $j('section.home .anima-4').addClass('activo');
          }, 5000);

          var contador = 0;

          this.numeroMenu = function(container){
            var $el;
            $j(container).each(function() {
              contador++;
              $el = $j(this);
              if (!$el.hasClass('con-numero')) {
                $el.prepend('<span>0'+contador+'.</span>');
                $el.addClass('con-numero');
              }
            });
          }

          this.numeroMenu('.menu-item');
          this.equalheight('.same');

          var width = $j(window).width();
          //console.log("Ancho de ventana:", width);
          if (width > 950){
            $j('.lamina').css('height',height);
          }
        
        }
  }
  }
  
  </script>
  
  <style scoped></style>
  