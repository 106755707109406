<!-- src/components/MainGlobal.vue -->
<template>
    <main>
    <section class="home lamina">
        <div class="container">
            <div class="columns twelve">
                <img src="/images/logo-vicapital.svg" alt="Vicapital" class="logo-home"/>
                <h2 class="anima-1 blur">MULTI FAMILY OFFICE</h2>
            </div>
        </div>
        <a href="#section-1" class="arrow anima-2 blur"></a>
        <div class="video">
            <video loop muted autoplay playsinline preload="auto">                
                <source src="/videos/video-home-vicapital.mp4" type="video/mp4">               
                Your browser does not support the video tag.
            </video>
        </div>
    </section>

    <div class="full" style="background-image: url('/images/fondo_nuevo.jpg');">
        <section id="section-1" class="laminita">
            <div class="container">
                <div class="columns eight offset-by-two">
                    <div class="columns twelve">
                        <h2>Quiénes <br>
                          Somos</h2>
                    </div>
                    <div class="columns ten offset-by-one centrado">
                        <p>Somos un <strong>Multi Family Office</strong> que ofrece asesoría independiente en la gestión de inversiones a personas, familias y fundaciones, a través de un servicio personalizado y a la medida. Contamos con un equipo de profesionales con destacada trayectoria en inversiones, dedicados exclusivamente al cuidado del patrimonio de nuestros clientes.</p>
                    </div>
                </div>
                <a href="#section-2" class="arrow"></a>
            </div>
        </section>

        <section id="section-2" class="nuestros-pilares2">
            <div class="container">
                <div class="columns eight offset-by-two">
                    <div class="columns twelve">
                        <h2>Nuestros<br> pilares</h2>
                    </div>
                    <div class="columns five offset-by-four">
                        <div class="item-cifras">
                            <div class="numero columns one same">01</div>
                            <div class="caja-pilar columns ten same">
                                <h3>Independencia</h3>
                                <div class="texto-85">Somos una empresa independiente.
                                  Nuestro único objetivo es <strong>identificar las mejores oportunidades del mercado y asesorar</strong> a nuestros clientes de acuerdo a sus objetivos.</div>
                            </div>
                        </div>
                        <div class="item-cifras">
                            <div class="numero columns one same">02</div>
                            <div class="caja-pilar columns ten same">
                                <h3>Experiencia</h3>
                                <div class="texto-85">Contamos con un equipo de gran experiencia, donde <strong>los socios están completamente involucrados</strong> en la relación con cada cliente.</div>
                            </div>
                        </div>
                        <div class="item-cifras">
                          <div class="numero columns one same">03</div>
                          <div class="caja-pilar columns ten same">
                              <h3>Foco</h3>
                              <div class="texto-85">La asesoría patrimonial de nuestros clientes es <strong>nuestro único negocio</strong>, lo que nos permite tener <strong>dedicación exclusiva y ser especialistas.</strong></div>
                          </div>
                      </div>
                      <div class="item-cifras">
                        <div class="numero columns one same">04</div>
                        <div class="caja-pilar columns ten same">
                            <h3>Transparencia</h3>
                            <div class="texto-85">Creemos en las <strong>relaciones de largo plazo</strong>, por lo que la <strong>confidencialidad y transparencia</strong> son pilares esenciales de nuestra empresa para generar vínculos de confianza.</div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="section-3" class="mision-vision">
            <div class="container">
                <div class="columns ten">
                    <div class="columns six offset-by-two">
                        <div class="item-cifras">
                            <div class="numero2 columns one same">MISIÓN</div>
                            <div class="caja-pilar columns ten same">
                              <h3 style="visibility: hidden;">TEST</h3>
                                <div class="texto-101"><strong>Brindar asesoría patrimonial especializada y enfocada en las necesidades individuales de nuestros clientes, orientándolos hacia decisiones de inversión que les permitan alcanzar sus objetivos de largo plazo.</strong></div>
                            </div>
                        </div>
                        <div class="item-cifras">
                            <div class="numero2 columns one same">VISIÓN</div>
                            <div class="caja-pilar columns ten same">
                                <h3 style="visibility: hidden;">TEST</h3>
                                <div class="texto-101"><strong>Ser referentes en asesoramiento patrimonial, reconocidos por nuestra integridad y compromiso con el éxito de nuestros clientes.</strong></div>
                            </div>
                        </div>
                        <div class="item-cifras">
                          <div class="numero2 columns one same">OBJETIVOS</div>
                          <div class="caja-pilar columns ten same">
                              <h3 style="visibility: hidden;">TEST</h3>
                              <div class="texto-100">
                              <ul class="custom-list">  
                                <li><strong>Construir relaciones de confianza y largo plazo con nuestros clientes, basada en el respeto mutuo y la transparencia.</strong></li>
                                <li><strong>Orientar a cada cliente en la estructuración de portafolios de inversión, diseñados de acuerdo con su perfil de riesgo y objetivos financieros.</strong></li>
                                <li><strong>Potenciar el trabajo en equipo, manteniendo un alto estándar profesional y un firme compromiso con el cumplimiento de los objetivos de cada cliente.</strong></li>
                                <li><strong>Realizar un monitoreo continuo del mercado para estar siempre al tanto de las tendencias y factores económicos globales.</strong></li>
                                <li><strong>Mantener una comunicación clara y constante, asegurándonos de que los clientes estén bien informados sobre sus inversiones y las dinámicas del mercado de manera comprensible y transparente.</strong></li>
                              </ul>

                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>

        
    </div>
</main>
  </template>
  
  <script>
  import jQuery from 'jquery';
  import { useHead } from '@vueuse/head';
  export default {
    name: 'MainGlobal',
    setup() {
    useHead({
      title: 'Multi Family Office Chile | VICAPITAL',
      meta: [
        { name: 'description', content: 'Multi Family Office ofrece un asesoría independiente en la gestión de inversiones a familias, a través de un servicio altamente personalizado' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'Multi Family Office Chile | VICAPITAL' },
        { property: 'og:description', content:  'Multi Family Office ofrece un asesoría independiente en la gestión de inversiones a familias, a través de un servicio altamente personalizado' },
        { property: 'og:url', content: 'https://vicapital.cl/' },
      ],
      link:
      [{ rel: 'canonical', href: 'https://vicapital.cl/' }]
    });
  },
  data() {
    return {
      imagesToPreload: [
        '/images/fondo-que-hacemos-exp-scaled.jpg',
        '/images/fondo-filosofia-exp-scaled.jpg',
        '/images/fondo-equipo-1.jpg',
        '/images/fondo-contacto-exp-scaled.jpg',
        '/images/fondo-filosofia-exp-scaled.jpg',
        // Agrega aquí todas las imágenes que necesites pre-cargar
      ]
    };
  },
    mounted() {
        this.initPage();
        this.setupWindowLoadEvent();
        this.preloadImages();
    },

  methods: {

    preloadImages() {

      this.imagesToPreload.forEach((src) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
        };
      });
    }
,
    initPage() {
      //console.log("iniciando init en main");
      
      this.$nextTick(() => {
        var $j = jQuery.noConflict();
        //console.log("No Conflict activado");

        const id = (v) => document.getElementById(v);

        var ovrl = id("overlay");
        if (ovrl) {
          $j('#overlay .logo').css('transition', 'all 1.5s ease');
          $j('#loader-logo-hover').css('opacity', '1');
          $j('.loader-logo').css('transition', 'all 0.2s ease');
          $j('.loader-logo').css('opacity', '0');
          setTimeout(function() {
            ovrl.style.opacity = 0;
          }, 1200);
          setTimeout(function() {
            ovrl.style.display = "none";
            $j('body').css('overflow', 'auto');
            $j('header').addClass('show-menu');
          }, 2000);
          //console.log("Elemento overlay encontrado");
        } else {
          //console.error("Elemento overlay no encontrado");
        }

        // Function to equalize heights
        

        // Window scroll and load functions
        const setupScrollEvents = () => {
          //console.log("Función principal de jQuery ejecutada");
          var height = $j(window).height(),
              header = $j("header");

          $j(window).scroll(() => {
            var scroll = $j(window).scrollTop();
            if (scroll >= height) {
              header.removeClass('sin-scroll').addClass("scroll");
            } else {
              header.removeClass("scroll").addClass('sin-scroll');
            }
          });

          $j(window).scroll(() => {
            var scroll = $j(window).scrollTop(),
                triggerBlur = $j(window).height() / 3;
            if (scroll >= triggerBlur) {
              $j('.full.blur').addClass("activo");
            } else {
              $j('.full.blur').removeClass("activo");
            }
          });
        };
        setupScrollEvents();

        
    

        $j(window).resize(() => {
          //console.log("Evento resize");

          this.equalheight('.same');
          var height = $j(window).height(),
              width = $j(window).width();
          if (width > 950){
            $j('.lamina').css('height',height);
          }
        });

        const setupMenuEvents = () => { 
          //console.log("Función anónima de jQuery ejecutada");
          $j('.nav .menu-item').has('ul').prepend('<span class="nav-click"><i class="nav-arrow"></i></span>');

          $j('.open-menu').click(function(){
            $j('header').toggleClass('open');
            $j('body').toggleClass('open-body');
            $j(this).toggleClass('open');
            return false;
          });
          /*
          $j('nav.principal .menu-item a').click(function(){
            console.log(location.pathname.replace(/^\//,''))
            console.log(this.pathname.replace(/^\//,''))
            console.log(location.hostname)
            console.log(this.hostname)
            
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
              var target = $j(this.hash);
              //console.log(target)

              target = target.length ? target : $j('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $j('html,body').animate({
                  scrollTop: target.offset().top
                }, 2000);
                $j('header').toggleClass('open');
                $j('body').toggleClass('open-body');
                $j('.open-menu').toggleClass('open');
                console.log('click');
                return false;
              }
            }
          });
          */

          $j('nav.info a').click(function(){
            var target = $j(this.hash);
            $j('.pop-left').removeClass('open');
            $j(target).toggleClass('open');
            return false;
          });

          $j('a.close-pop').click(function(){
            var target = $j(this.hash);
            $j(target).toggleClass('open');
            return false;
          });

          $j('.nav-responsive .nav-click').on('click', function(){
            if($j(this).parent().hasClass('activo')){
              $j('nav.nav .menu-item').removeClass('activo');
            } else {
              $j('nav.nav .menu-item').removeClass('activo');
              $j(this).parent().toggleClass('activo');
            }
            //console.log('submenu');
          });
          /*
          $j('.scroll').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
              var target = $j(this.hash);
              target = target.length ? target : $j('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $j('html,body').animate({
                  scrollTop: target.offset().top
                }, 2000);
                console.log('.scroll');
                return false;
              }
            }
          });
          */
        };

        setupMenuEvents();
      });
    },

    setupWindowLoadEvent() {

        this.equalheight = function(container) {
          //console.log("Iniciando equalheight");
          var currentTallest = 0,
              currentRowStart = 0,
              rowDivs = [],
              $el,
              topPosition = 0;

          $j(container).each(function() {
            $el = $j(this);
            $j($el).height('auto');
            topPosition = $el.position().top;

            if (currentRowStart != topPosition) {
              for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
              }
              rowDivs.length = 0; // empty the array
              currentRowStart = topPosition;
              currentTallest = $el.height();
              rowDivs.push($el);
            } else {
              rowDivs.push($el);
              currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
            }
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
          });
          //console.log("equalheight completado");
        }
            const $j = jQuery.noConflict();
          //console.log("Entrando a evento window load");
          
          this.equalheight('.same');
          var height = $j(window).height();

          const id = (v) => document.getElementById(v);
          var ovrl = id("overlay");
          $j('#overlay .logo').css('transition','all 1.5s ease');
          $j('#loader-logo-hover').css('opacity','1');
          $j('.loader-logo').css('transition','all 0.2s ease');
          $j('.loader-logo').css('opacity','0');
          setTimeout(function(){ 
            ovrl.style.opacity = 0;
          }, 1200);
          setTimeout(function(){ 
            ovrl.style.display = "none";
            $j('body').css('overflow','auto');
          }, 2000);
          
          setTimeout(function(){ 
            $j('section.home .anima-1').addClass('activo');
          }, 2000);
          setTimeout(function(){ 
            $j('section.home .anima-2').addClass('activo');
          }, 2500);
          setTimeout(function(){ 
            $j('section.home .anima-3').addClass('activo');
          }, 4000);
          setTimeout(function(){ 
            $j('section.home .anima-4').addClass('activo');
          }, 5000);

          var contador = 0;

          this.numeroMenu = function(container){
            var $el;
            $j(container).each(function() {
              contador++;
              $el = $j(this);
              if (!$el.hasClass('con-numero')) {
                $el.prepend('<span>0'+contador+'.</span>');
                $el.addClass('con-numero');
              }

              
            });
          }

          this.numeroMenu('.menu-item');
          this.equalheight('.same');

          var width = $j(window).width();
          //console.log("Ancho de ventana:", width);
          if (width > 950){
            $j('.lamina').css('height',height);
          }
        
        }
  }
  }
  
  </script>
  
  <style scoped>
  </style>
  