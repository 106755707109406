<template>
  <main>
    <article class="cabecera-interior" style="background-image: url(/images/fondo-equipo-1.jpg);">
    <div class="cabecera-interior-caja">
      <div class="container">
      <div class="titulo-cabecera columns four">
        <h1>Equipo</h1>
      </div>
      <div class="contenido-cabecera columns seven offset-by-one">
        <h2>SOMOS UN GRUPO DE PROFESIONALES CON DESTACADA TRAYECTORIA EN INVERSIONES</h2>
        <p>Combinamos múltiples experiencias, habiendo trabajado en fondos de inversión, private equity, mesas de dinero, finanzas corporativas y banca privada.</p>
      </div>
      </div>
      <a href="#seccion-equipo" class="scroll"></a>
    </div>
    </article>
    <section id="seccion-equipo" class="seccion full">
    <div class="container-big">
      <div class="columns eight offset-by-two">
      <div class="tipo" v-for="(grupo, grupoIndex) in grupos" :key="grupoIndex">
        <h2>{{ grupo.nombre }}</h2>
        <div class="row">
        <div v-for="persona in grupo.miembros" :key="persona.id" class="miembro-equipo four columns same">
          <router-link :to="{ name: 'PersonaDetalle', params: { id: persona.id } }" class="hover">
          <div class="hover-img"><span>VER MÁS</span></div>
          <img width="1082" height="1077" :src="persona.imagen" :alt="persona.nombre" class="attachment-full size-full wp-post-image"  sizes="(max-width: 1082px) 100vw, 1082px"/>
          <div class="caja-equipo">
            <h3 v-html="persona.nombre"></h3>
            <h4>{{ persona.cargo }}</h4>
          </div>
          </router-link>
        </div>
        </div>
      </div>
      </div>
    </div>
    </section>
  </main>
  </template>
  <script>
  import jQuery	from 'jquery';
  import { useHead } from '@vueuse/head';
  export default {
  name: 'EquipoPage',
  setup() {
    useHead({
      title: 'Nuestro Equipo | VICAPITAL',
      meta: [
        { name: 'description', content: 'SOMOS UN GRUPO DE PROFESIONALES CON DESTACADA TRAYECTORIA EN INVERSIONES. Combinamos múltiples experiencias, habiendo trabajado en fondos de inversión, private equity, mesas de dinero, finanzas corporativas y banca privada.' },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: 'Nuestro Equipo | VICAPITAL' },
        { property: 'og:description', content:  'SOMOS UN GRUPO DE PROFESIONALES CON DESTACADA TRAYECTORIA EN INVERSIONES. Combinamos múltiples experiencias, habiendo trabajado en fondos de inversión, private equity, mesas de dinero, finanzas corporativas y banca privada.' },
        { property: 'og:url', content: 'https://vicapital.cl/equipo' },
      ],
      link:
      [{ rel: 'canonical', href: 'https://vicapital.cl/equipo' }]
    });
  },
  data() {
  return {
	grupos: [
        {
          nombre: 'Socios',
          miembros: [
            {
              id: 'ignacio-silva',
              nombre: 'Ignacio<br />Silva',
              nombreHTML: 'Ignacio<br />Silva<br />Lombardi',
              cargo: 'SOCIO',
              imagen: '/images/equipo/Ignacio-Silva-Lombardi.png',
              imagen_grande: '/images/equipo/Ignacio-silva.jpg',
              texto: "<p>En Julio 2014 se integra como socio de Vicapital. Anteriormente, Ignacio trabajó en IM Trust (actualmente Credicorp Capital) en el área de finanzas corporativas participando en transacciones de fusiones y adquisiciones, emisiones de bonos y aperturas en bolsa. Posteriormente, entre los años 2008 y 2012, Ignacio trabajó en Southern Cross Group, uno de los principales gestores de private equity de Latinoamérica, participando en la administración de las compañías del portafolio y evaluando oportunidades de inversión en Latinoamérica.</p><p>Ignacio es Ingeniero Comercial de la Universidad Católica de Chile y obtuvo su MBA de The UCLA Anderson School of Business, donde recibió el J. Fred Weston Award (Excellence in Finance). Durante su MBA, Ignacio participó en un grupo que administró una parte del endowment de UCLA.</p><p>Miembro del equipo de inversiones que elabora las recomendaciones de inversión.</p><p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>",
              email: 'isilva@vicapital.cl'
            },
            {
              id: 'daniela-infante',
              nombre: 'Daniela<br />Infante',
              nombreHTML: 'DANIELA<br />INFANTE<br />Stewart',
              cargo: 'SOCIA',
              imagen: '/images/equipo/DANIELA-INFANTE.png',
				imagen_grande: '/images/equipo/DANIELA-INFANTE.jpg',
              texto: "<p>Socia Fundadora de Vicapital. Daniela tiene más de 20 años de experiencia en el mercado de capitales. Anteriormente, Daniela trabajó en Finanzas de Concha y Toro. Posteriormente, ingresó el año 1999 al departamento de Estudios y Estrategias de Tanner Corredores de Bolsa. Como Gerente Comercial de Tanner, lideró el área, gestionando carteras de clientes de alto patrimonio y desarrollando el plan comercial de Tanner. Llegó a ser socia de la Corredora. El año 2012 formó parte del equipo fundador de Vicapital.</p><p>Daniela es Ingeniera Comercial de la Pontificia Universidad Católica de Chile.</p><p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>",
              email: 'dinfante@vicapital.cl'
            },
            {
              id: 'emiliano-villaseca',
              nombre: 'Emiliano<br />Villaseca',
              nombreHTML: 'EMILIANO<br />VILLASECA<br />Gorman',
              cargo: 'SOCIO',
              imagen: '/images/equipo/EMILIANO-VILLASECA.png',
				imagen_grande: '/images/equipo/Emiliano-Villaseca.jpg',
              texto: "<p><p>Socio Fundador de Vicapital. Emiliano tiene más de 18 años de experiencia en el mercado de capitales. Desde al año 2004 formó parte del grupo de empresas Tanner, donde ocupó los cargos de Gerente de Inversiones, Gerente Comercial, Gerente General y Director Ejecutivo. Durante sus años de trabajó en Tanner, Emiliano gestionó carteras de inversionistas, desarrolló el plan comercial de la compañía, lideró el equipo de la mesa de dinero, desarrolló el área de los Fondos de Inversión y trabajó en el desarrollo e implementación del plan estratégico de la empresa. El año 2012 formó parte del equipo fundador de Vicapital.</p><p>Emiliano es Ingeniero Comercial de la Pontificia Universidad Católica de Chile.</p><p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p></p>",
              email: 'evillaseca@vicapital.cl'
            }
          ]
        },
        {
          nombre: 'Consejo asesor',
          miembros: [
            {
              id: 'jose-de-gregorio',
              nombre: 'José<br />De Gregorio',
              nombreHTML: 'José<br />De Gregorio<br />Rebeco',
              cargo: 'Asesor',
              imagen: '/images/equipo/Jose-De-Gregorio.png',
				imagen_grande: '/images/equipo/Jose-De-Gregorio.jpg',
              texto: "<p>José es decano de la Facultad de Economía y Negocios de la Universidad de Chile. Fue Presidente del Banco Central y Tri-Ministro en las carteras de Economía, Minería y Energía de Chile.</p><p>Magíster en Ingeniería e Ingeniero Civil Industrial de la Universidad de Chile. Doctor en Economía de MIT.</p></p>",
              //email: 'jose@vicapital.cl'
            },
            {
              id: 'alfredo-alcaino',
              nombre: 'Alfredo<br />Alcaíno',
              nombreHTML: 'ALFREDO<br />ALCAÍNO<br />Lamarca',
              cargo: 'Asesor',
              imagen: '/images/equipo/ALFREDO-ALCAINO-1.jpg',
				imagen_grande: '/images/equipo/ALFREDO-ALCAINO-2.jpg',
              texto: "<p>Alfredo es socio de Assetplan, el mayor operador de renta residencial en Chile. Anteriormente trabajó en Celfin Capital, Veta3 y el Ministerio del Interior.</p><p>Ingeniero Civil de la Pontificia Universidad Católica de Chile y MBA de Kellogg School of Management.</p>",
              //email: 'aalcaino@vicapital.cl'
            },
            {
              id: 'eduardo-torretti',
              nombre: 'Eduardo<br />Torretti',
              nombreHTML: 'Eduardo<br />Torretti<br />Schmidt',
              cargo: 'Asesor',
              imagen: '/images/equipo/Eduardo-Torreti.png',
				imagen_grande: '/images/equipo/Eduardo-Torreti.jpg',
              texto: "<p>Eduardo es socio de Torretti &amp; Cía, estudio de abogados especializado en derecho tributario, con una sólida práctica en asuntos corporativos, familias empresarias y clientes de alto patrimonio.</p><p>Abogado de la Pontificia Universidad Católica de Chile y LLM de Universidad de Leiden, Holanda.</p>",
              //email: 'etorretti@vicapital.cl'
            }
          ]
        },
        {
          nombre: 'Equipo',
          miembros: [
            {
              id: 'pablo-achondo',
              nombre: 'Pablo<br />Achondo',
              nombreHTML: 'Pablo<br />Achondo<br />Díaz',
              cargo: 'Inversiones',
              imagen: '/images/equipo/Pablo-Achondo.png',
				imagen_grande: '/images/equipo/Pablo-Achondo.jpg',
              texto: "<p>Pablo se incorporó a Vicapital en Octubre de 2013.</p><p>Previo a su ingreso a Vicapital, Pablo trabajó en AFP Provida.</p><p>Pablo es Ingeniero Comercial de la Universidad Andrés Bello de Chile.</p><p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>",
              email: 'pachondo@vicapital.cl'
            },
            {
              id: 'isabel-allendes',
              nombre: 'Isabel<br />Allendes',
              nombreHTML: 'ISABEL<br />ALLENDES<br />Correa',
              cargo: 'Inversiones',
              imagen: '/images/equipo/Isabel-Allendes-1-2.jpg',
				imagen_grande: '/images/equipo/Isabel-Allendes-2.jpg',
              texto: "<p>Isabel se incorporó a Vicapital en Noviembre de 2020.</p><p>Anteriormente Isabel trabajó como Investor Relations en el Banco de Chile y luego en el Banco de Crédito e Inversiones como operadora de mesa dólar y retail de la corredora de bolsa.</p><p>Isabel es Ingeniera Comercial de la Universidad Adolfo Ibáñez.</p><p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>",
              email: 'iallendes@vicapital.cl'
            },
            {
              id: 'maximiliano-aburto',
              nombre: 'Maximiliano<br />Aburto',
              nombreHTML: 'MAXIMILIANO<br />ABURTO<br />Soto',
              cargo: 'Inversiones',
              imagen: '/images/equipo/Maximiliano-Aburto.png',
				imagen_grande: '/images/equipo/Maximiliano-Aburto.jpg',
              texto: "<p>Maximiliano se incorporó a Vicapital en Julio de 2019.</p><p>Maximiliano es Ingeniero Comercial de la Universidad de Chile con Mención en Economía.</p><p>Miembro del equipo de inversiones que elabora las recomendaciones de inversión.</p><p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>",
              email: 'maburto@vicapital.cl'
            },
            {
              id: 'gonzalo-araos',
              nombre: 'Gonzalo<br />Araos',
              nombreHTML: 'GONZALO<br />ARAOS<br />BAERISWYL',
              cargo: 'Inversiones',
              imagen: '/images/equipo/Gonzalo-Araos.png',
				imagen_grande: '/images/equipo/gonzalo-araos.jpg',
              texto: "<p>Gonzalo se incorporó a Vicapital en Junio de 2022.</p><p>Anteriormente, Gonzalo trabajó en BICE Inversiones como analista del área de fondos balanceados de la Administradora General de Fondos.</p><p>Gonzalo es Ingeniero Comercial de la Pontificia Universidad Católica de Chile.</p><p>Miembro del equipo de inversiones que elabora las recomendaciones de inversión.</p><p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>",
              email: 'garaos@vicapital.cl'
            },
            {
              id: 'rodrigo-montes',
              nombre: 'Rodrigo<br />Montes',
              nombreHTML: 'RODRIGO<br />MONTES<br />PÉREZ DE ARCE',
              cargo: 'Inversiones',
              imagen: '/images/equipo/Rodrigo-Montes.png',
				imagen_grande: '/images/equipo/Rodrigo-Montes.jpg',
              texto: "<p>Rodrigo se incorporó a Vicapital en Vicapital en Marzo de 2022.</p><p>Anteriormente, Rodrigo trabajo en FYNSA AGF en el área de administración y estructuración de fondos de inversión alternativos.</p><p>Rodrigo es Ingeniero Comercial de la Universidad Católica de Chile.</p><p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>",
              email: 'rmontes@vicapital.cl'
            },
            {
              id: 'julio-pesce',
              nombre: 'Julio<br />Pesce',
              nombreHTML: 'JULIO<br />PESCE<br />Soto',
              cargo: 'Inversiones',
              imagen: '/images/equipo/Julio-Pesce.png',
				imagen_grande: '/images/equipo/Julio-Pesce.jpg',
              texto: "<p>Julio se incorporó a Vicapital en Noviembre de 2021.</p><p>Julio es Ingeniero Comercial, mención en Economía, de la Universidad de Chile y Magister en Análisis Económico en la misma casa de estudio.</p><p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>",
              email: 'jpesce@vicapital.cl'
            },
            {
              id: 'maria-luisa',
              nombre: 'María Luisa<br />Pérez de Arce',
              nombreHTML: 'MARÍA<br />LUISA<br />PÉREZ DE ARCE',
              cargo: 'Inversiones',
              imagen: '/images/equipo/normal.jpg',
				imagen_grande: '/images/equipo/malu-codo.jpg',
              texto: "<p>María Luisa se incorporó a Vicapital en Mayo de 2024.</p><p>María Luisa es Ingeniera Comercial de la Universidad de los Andes.</p>",
              email: 'mperezdearce@vicapital.cl'
            },
            {
              id: 'patricio-ramirez',
              nombre: 'Patricio<br />Ramirez',
              nombreHTML: 'PATRICIO<br />RAMIREZ<br />VILCHES',
              cargo: 'Administración y Finanzas',
              imagen: '/images/equipo/Patricio-Ramirez.png',
				imagen_grande: '/images/equipo/Patricio-Ramirez.jpg',
              texto: "<p>Patricio se incorporó a Vicapital en Abril de 2022.</p><p>Patricio es Ingeniero en Información y Control de Gestión de la Universidad de Chile.</p>",
              email: 'pramirez@vicapital.cl'
            },
            {
              id: 'juan-carlos-obreque',
              nombre: 'Juan Carlos<br />Obreque',
              nombreHTML: 'Juan<br />Carlos<br />Obreque',
              cargo: 'Mercados',
              imagen: '/images/equipo/Juan-Carlos-Obreque.png',
				imagen_grande: '/images/equipo/Juan-Carlos-Obreque.jpg',
              texto: "<p>Juan Carlos se incorporó a Vicapital en Enero de 2013.</p><p>Anteriormente, Juan Carlos se desempeñó en el área de tesorería y operaciones de Tanner Corredores de Bolsa y Euroamérica.</p>",
              email: 'jobreque@vicapital.cl'
            },
            {
              id: 'romina-galvez',
              nombre: 'Romina<br />Galvez',
              nombreHTML: 'ROMINA<br />GALVEZ<br />YEFI',
              cargo: 'Inversiones',
              imagen: '/images/equipo/Romina-Galvez.png',
				imagen_grande: '/images/equipo/Romina-Galvez.jpg',
              texto: "<p>Romina se incorporó a Vicapital en Diciembre de 2019.</p><p>Romina es Ingeniero Comercial de la Universidad de los Andes.</p><p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>",
              email: 'rgalvez@vicapital.cl'
            },
            {
              id: 'esteban-tapia',
              nombre: 'Esteban<br />Tapia',
              nombreHTML: 'ESTEBAN<br />TAPIA<br />MANZANO',
              cargo: 'Tecnología',
              imagen: '/images/equipo/43841-e1697817472577.jpg',
				imagen_grande: '/images/equipo/et-codo.jpg',
              texto: "<p>Esteban se incorporó a Vicapital en Junio de 2023.</p><p>Esteban es Ingeniero Civil en Informática de la Universidad Técnica Federico Santa María.</p>",
              email: 'etapia@vicapital.cl'
            },
            {
              id: 'maria-jose-prieto',
              nombre: 'María José<br />Prieto',
              nombreHTML: 'MARÍA<br />JOSÉ<br />PRIETO',
              cargo: 'Office Manager',
              imagen: '/images/equipo/cote-cortada2.jpg',
				imagen_grande: '/images/equipo/cote-normal.jpg',
              texto: "<p>María José se incorporó a Vicapital en Julio de 2021.</p><p>María José es Ingeniera Comercial de la Universidad de los Andes.</p>",
              email: 'mjprieto@vicapital.cl'
            },
            {
              id: 'emilio-granados',
              nombre: 'Emilio<br />Granados',
              nombreHTML: 'EMILIO<br />GRANADOS<br />CASTAÑEDA',
              cargo: 'Mercados',
              imagen: '/images/equipo/Emilio-zoom.jpg',
				imagen_grande: '/images/equipo/Emilio-completo.jpg',
              texto: "<p>Emilio se incorporó a Vicapital en Julio de 2024.</p><p>Emilio es Ingeniero Comercial de la Universidad Adolfo Ibáñez.</p>",
              email: 'egranados@vicapital.cl'
            },
            {
              id: 'pedro-gonzales',
              nombre: 'Pedro<br />Gonzales',
              nombreHTML: 'PEDRO<br />GONZALEZ<br />MATURANA',
              cargo: 'Asistente',
              imagen: '/images/equipo/Pedro-GONZALES.png',
				imagen_grande: '/images/equipo/Pedro-GONZALES.jpg',
              texto: "<p>Pedro se incorporó a Vicapital en Enero de 2017.</p>",
              //email: 'pgonzales@vicapital.cl'
            },
            {
              id: 'carolina-ramirez',
              nombre: 'Carolina<br />Ramirez',
              nombreHTML: 'CAROLINA<br />RAMIREZ<br />RIVERA',
              cargo: 'Asistente Ejecutiva',
              imagen: '/images/equipo/CAROLINA-RAMIREZ-1-2.jpg',
				imagen_grande: '/images/equipo/CAROLINA-RAMIREZ-2.jpg',
              texto: "<p>Carolina se incorporó a Vicapital en Septiembre de 2012.</p><p>Anteriormente, Carolina trabajo como asistente ejecutiva en Tanner Corredores de Bolsa.</p><p>Carolina es Secretaria Bilingüe.</p>",
              email: 'cramirez@vicapital.cl'
            }
          ]
        }
      ],
  };
  },

  methods: {

  initPage() {
  const $j = jQuery.noConflict();
  //console.log("Iniciando mounted servicios")
  const id = (v) => document.getElementById(v);
  var ovrl = id("overlay");
  ovrl.style.display = "none";

  var logo_opacity = id("logo-header");
    logo_opacity.style.opacity = 1;

  $j('.scroll').on('click', function(event) {
  event.preventDefault();
  const target = this.hash;
  const $target = $j(target);

  $j('html, body').animate({
  scrollTop: $target.offset().top
  }, 2000, function() {
  });
  });
  // Equal height function
  

  // Window scroll and load functions
  const setupScrollEvents = () => {
  //console.log("Función principal de jQuery ejecutada");
  var height = $j(window).height(),
  header = $j("header");

  $j(window).scroll(() => {
  var scroll = $j(window).scrollTop();
  if (scroll >= height) {
    header.removeClass('sin-scroll').addClass("scroll");
  } else {
    header.removeClass("scroll").addClass('sin-scroll');
  }
  });

  $j(window).scroll(() => {
  var scroll = $j(window).scrollTop(),
    triggerBlur = $j(window).height() / 3;
  if (scroll >= triggerBlur) {
    $j('.full.blur').addClass("activo");
  } else {
    $j('.full.blur').removeClass("activo");
  }
  });
  };
  setupScrollEvents();

  

$j(window).resize(function() {
  const equalheight = (container) => {
  let currentTallest = 0,
  currentRowStart = 0,
  rowDivs = [],
  $el,
  topPosition = 0;

  $j(container).each(function() {
  $el = $j(this);
  $j($el).height('auto');
  topPosition = $el.position().top;

  if (currentRowStart != topPosition) {
  for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
    rowDivs[currentDiv].height(currentTallest);
  }
  rowDivs.length = 0; // empty the array
  currentRowStart = topPosition;
  currentTallest = $el.height();
  rowDivs.push($el);
  } else {
  rowDivs.push($el);
  currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
  for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
  rowDivs[currentDiv].height(currentTallest);
  }
  });
  };
  equalheight('.same');
  const height = $j(window).height();
  const width = $j(window).width();
  if (width > 950){
  $j('.lamina').css('height',height);
  }
  });
}
,
setupWindowLoadEvent() {
  const equalheight = (container) => {
  let currentTallest = 0,
  currentRowStart = 0,
  rowDivs = [],
  $el,
  topPosition = 0;

  $j(container).each(function() {
  $el = $j(this);
  $j($el).height('auto');
  topPosition = $el.position().top;

  if (currentRowStart != topPosition) {
  for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
    rowDivs[currentDiv].height(currentTallest);
  }
  rowDivs.length = 0; // empty the array
  currentRowStart = topPosition;
  currentTallest = $el.height();
  rowDivs.push($el);
  } else {
  rowDivs.push($el);
  currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
  for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
  rowDivs[currentDiv].height(currentTallest);
  }
  });
  };
  const $j = jQuery.noConflict();
  equalheight('.same');
  const height = $j(window).height();
  const width = $j(window).width();
  if (width > 950){
  $j('.lamina').css('height',height);
  }

  const numeroMenu = (container) => {
  let contador = 0;
  $j(container).each(function() {
  contador++;
  const $el = $j(this);
  if (!$el.hasClass('con-numero')) {
    $el.prepend('<span>0'+contador+'.</span>');
    $el.addClass('con-numero');
  }
  });
  };

  

  numeroMenu('.menu-item');
  equalheight('.same');

  // Menu functions
  $j('.nav .menu-item').has('ul').prepend('<span class="nav-click"><i class="nav-arrow"></i></span>');

  $j('.open-menu').click(function() {
  $j('header').toggleClass('open');
  $j('body').toggleClass('open-body');
  $j(this).toggleClass('open');
  return false;
  });
  /*
  $j('nav.principal .menu-item a').click(function() {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
  let target = $j(this.hash);
  target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
  if (target.length) {
    $j('html,body').animate({
    scrollTop: target.offset().top
    }, 2000);
    $j('header').toggleClass('open');
    $j('body').toggleClass('open-body');
    $j('.open-menu').toggleClass('open');
    return false;
  }
  }
  });
  */

  $j('nav.info a').click(function() {
  const target = $j(this.hash);
  $j('.pop-left').removeClass('open');
  $j(target).toggleClass('open');
  return false;
  });

  $j('a.close-pop').click(function() {
  const target = $j(this.hash);
  $j(target).toggleClass('open');
  return false;
  });

  $j('.nav-responsive .nav-click').on('click', function() {
  if($j(this).parent().hasClass('activo')){
    $j('nav.nav .menu-item').removeClass('activo');
  } else {
  $j('nav.nav .menu-item').removeClass('activo');
  $j(this).parent().toggleClass('activo');
  }
  });
  /*
  $j('.scroll').click(function() {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
  let target = $j(this.hash);
  target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
  if (target.length) {
    $j('html,body').animate({
    scrollTop: target.offset().top
    }, 2000);
    return false;
  }
  }
  });
  */
  
  },



  
  },

  mounted() {
	this.$root.personas = this.grupos.flatMap(grupo => grupo.miembros);
	this.initPage();
	this.setupWindowLoadEvent();
  }
 
  }

  </script>
  
  <style scoped></style>
  